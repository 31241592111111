* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-family: montserrat, sans-serif;
  background-color: #000;
}

h1 {
  display: block;
  font-family: inherit;
  font-size: 4rem;
  font-weight: 800;
  color: rgb(225, 225, 255);
  text-align:center;
}

h3 {
  display: block;
  font-family: inherit;
  font-size: 2rem;
  font-weight: 800;
  color: rgb(225, 225, 255);
  text-align:center;
}

p {
  display: block;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 300;
  color: rgb(225, 225, 255);
  text-align: left;
  margin-left:2rem;
  margin-right:2rem;
  margin-top:0;
  margin-bottom:1rem;
  word-wrap: break-word;
  white-space: pre-wrap;
  flex: 0 1 20%;
}

.container {
  width:100vw;
  height:100vh;
  margin:5%;
  padding: 3%;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: all;
  touch-action:none;
}

.flex {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content:flex-start;
  margin: 0;
}

.flex-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content:space-around;
  align-content:flex-start;
  margin: 0;
}

.btn{
  display: block;
  background-color:rgb(85, 85, 255);
  color: rgb(200, 200, 255);
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 800;
  border-width: 4px;
  border-radius: 3rem;
  border-color:orange;
  cursor: pointer;
  padding: 0.5rem 2rem;
  touch-action:none;
  margin: 1rem;
}

a{
  color: rgb(200, 255, 255);
}

@media (orientation: landscape) {
  p {
    font-size: 1.5rem;
    margin-left:1rem;
    margin-right:1rem;
    margin-bottom:0.7rem;
  }
}
/*
@media (orientation: portrait) {

} */

/***************************************
 * Device Orientation Portrait, iPhones
 ***************************************/
 /* @media (orientation: portrait) and (max-width: 450px) {

} */

/****************************************
 * Device Orientation Landscape, iPhones
 ****************************************/
/* @media (orientation: landscape) and (max-width: 900px) {

} */

/************************************
* Device Orientation Portrait, iPads
*************************************/
/* @media (orientation: portrait) and (min-width: 451px) {

} */

/**************************************
* Device Orientation Landscape, iPads
**************************************/
/* @media (orientation: landscape) and (min-width: 951px) {

} */

/**************************************
* Desktops
**************************************/
@media (min-width: 1200px) {
  p {
    font-size: 4rem;
  }
}
