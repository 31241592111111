* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  background-color: #000;
  font-family: montserrat, sans-serif;
}

h1 {
  color: #e1e1ff;
  text-align: center;
  font-family: inherit;
  font-size: 4rem;
  font-weight: 800;
  display: block;
}

h3 {
  color: #e1e1ff;
  text-align: center;
  font-family: inherit;
  font-size: 2rem;
  font-weight: 800;
  display: block;
}

p {
  color: #e1e1ff;
  text-align: left;
  word-wrap: break-word;
  white-space: pre-wrap;
  flex: 0 20%;
  margin: 0 2rem 1rem;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 300;
  display: block;
}

.container {
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: all;
  touch-action: none;
  width: 100vw;
  height: 100vh;
  margin: 5%;
  padding: 3%;
}

.flex {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  display: flex;
}

.flex-buttons {
  flex-flow: wrap;
  place-content: flex-start space-around;
  align-items: stretch;
  margin: 0;
  display: flex;
}

.btn {
  color: #c8c8ff;
  cursor: pointer;
  touch-action: none;
  background-color: #55f;
  border-width: 4px;
  border-color: orange;
  border-radius: 3rem;
  margin: 1rem;
  padding: .5rem 2rem;
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 800;
  display: block;
}

a {
  color: #c8ffff;
}

@media (orientation: landscape) {
  p {
    margin-bottom: .7rem;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 1.5rem;
  }
}

@media (width >= 1200px) {
  p {
    font-size: 4rem;
  }
}
/*# sourceMappingURL=index.f92a3dd8.css.map */
